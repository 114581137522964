.tutorials {
  min-height: 100vh;
  background: #57bc2c49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorials > .main {
  margin-top: 100px;
  display: flex;
  width: 1150px;
  align-items: center;
  gap: 20px;
  margin-bottom: 60px;
}
.tutorials > .main > img {
  width: 450px;
  border-radius: 14px;
}
.tutorials > .main > div {
  display: flex;
  flex-direction: column;
}
.tutorials > .main > div > .head {
  font-size: 32px;
  color: #1c4d06;
  font-weight: 600;
  margin-bottom: 13px;
  line-height: 38px;
}
.tutorials > .main > div > .desc {
  font-size: 18px;
  color: #1c4d06;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: justify;
}
.tutorials > .main > div > a {
  text-decoration: none;
  background: #57bc2c;
  font-size: 18px;
  padding: 10px 18px;
  border-radius: 9px;
  color: white;
  font-weight: 600;
  width: fit-content;
  margin-bottom: 10px;
}
.tutorials > .list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 1150px;
  gap: 35px;
  margin-bottom: 30px;
}
.tutorials > .list > a {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  color: #1c4d06;
  width: 360px;
  background: #6adf3977;
  border-radius: 10px;
  box-shadow: 4px 4px 8px 2px #11360171;
  transition: 1s all;
}
.tutorials > .list > a:hover {
  box-shadow: 3px 3px 8px 3px #113601fd;
}
.tutorials > .list > a > img {
  width: 360px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  margin-bottom: 16px;
}
.tutorials > .list > a > .head {
  font-size: 20px;
  color: #1c4d06;
  font-weight: 600;
  line-height: 22px;
  margin: 0 10px 8px;
}
.tutorials > .list > a > .desc {
  font-size: 15.5px;
  color: #1c4d06;
  font-weight: 500;
  margin: 0 10px 16px;
  text-align: justify;
}

.tutorialScreen {
  min-height: 100vh;
  background: #57bc2c49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.tutorialScreen > .container {
  width: 1150px;
  padding: 30px 0;
  color: #1c4d06;
}
.tutorialScreen > .container > .header {
  margin: 20px 0;
  font-size: 32px;
  font-weight: 700;
}
.tutorialScreen > .container > .desc {
  margin: 10px 0;
  font-size: 18.5px;
  text-align: justify;
}
.tutorialScreen > .container > .subHeader {
  margin: 15px 0;
  font-size: 26px;
  font-weight: 700;
}
.tutorialScreen > .container > .list {
  margin: 10px 0;
  font-size: 18.5px;
}
.tutorialScreen > .container > .list > li {
  margin: 5px 0;
}
.tutorialScreen > .container > .images {
  display: flex;
  justify-content: space-between;
  margin: 50px;
}
.tutorialScreen > .container > .bottom {
  display: flex;
  flex-direction: row;
  margin: 20px 0;
  justify-content: space-between;
}
.tutorialScreen > .container > .bottom > a {
  text-decoration: none;
  background: #57bc2c;
  font-size: 18px;
  padding: 10px 18px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
  width: fit-content;
}

@media screen and (max-width: 1250px) {
  .tutorials > .main {
    width: 94%;
  }
  .tutorials > .list {
    flex-wrap: wrap;
    width: 94%;
    justify-content: center;
  }
  .tutorialScreen > .container {
    width: 900px;
  }
  .tutorialScreen > .container > .images {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
}
@media screen and (max-width: 1000px) {
  .tutorials > .main {
    flex-direction: column;
    width: 500px;
  }
  .tutorials > .main > img {
    width: 500px;
  }
  .tutorialScreen > .container {
    width: 90%;
  }
}
@media screen and (max-width: 525px) {
  .tutorials > .main {
    flex-direction: column;
    width: 310px;
  }
  .tutorials > .main > img {
    width: 310px;
  }
  .tutorials > .main > div > .head {
    font-size: 22px;
    line-height: 23px;
  }
  .tutorials > .main > div > .desc {
    font-size: 14px;
    text-align: left;
  }
  .tutorials > .list > a {
    scale: 0.8;
  }
  .tutorials > .list {
    gap: 0px;
  }
  .tutorialScreen > .container > .images > img {
    width: 300px;
    height: auto;
  }
  .tutorialScreen > .container > .header {
    margin: 10px 0;
    font-size: 24px;
  }
  .tutorialScreen > .container > .desc {
    margin: 6px 0;
    font-size: 15px;
  }
  .tutorialScreen > .container > .subHeader {
    margin: 8px 0;
    font-size: 19px;
  }
  .tutorialScreen > .container > .list {
    margin: 6px 2px;
    font-size: 15px;
  }
}
