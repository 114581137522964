@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
html {
  scrollbar-width: none;
}
