.home {
  min-height: 100vh;
  background: #57bc2c49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home > .hero {
  width: 1200px;
  margin: 40px 0;
  border-radius: 10px;
  height: 500px;
  background: url("../../Assets/hero.png") center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 50px 50px;
}
.home > .hero > .head {
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  text-shadow: -1px 1px 0 #000, 1px 1px 0 #000, 1px -1px 0 #000, -1px -1px 0 #000;
  margin-bottom: 20px;
}
.home > .hero > .desc {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  text-shadow: -0.5px 0.5px 0 #000, 0.5px 0.5px 0 #000, 0.5px -0.5px 0 #000, -0.5px -0.5px 0 #000;
  margin-bottom: 30px;
  width: 60%;
  text-align: justify;
}
.home > .hero > a {
  background: #57bc2c;
  width: fit-content;
  padding: 10px 24px;
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 6px;
}

.home > .features {
  display: flex;
  flex-direction: column;
  width: 1200px;
  gap: 20px;
  margin-bottom: 30px;
  cursor: pointer;
}
.home > .features > .header {
  font-size: 32px;
  font-weight: 600;
  color: #1c4d06;
}
.home > .features > .list {
  display: flex;
  justify-content: space-between;
}
.home > .features > .list > .feature {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 220px;
  padding: 16px 20px;
  border: 2px solid #1c4d06;
  border-radius: 10px;
  box-shadow: -1px 0px 8px 1.5px rgba(0, 0, 0, 0.75);
  gap: 8px;
}
.home > .features > .list > .feature > img {
  width: 30px;
}
.home > .features > .list > .feature > .title {
  font-size: 16px;
  font-weight: 600;
  color: #1c4d06;
}
.home > .features > .list > .feature > .desc {
  font-size: 14px;
  font-weight: 500;
  color: #1c4d06;
}

.home > .bottom {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}
.home > .bottom > .head {
  font-size: 28px;
  font-weight: 600;
  color: #1c4d06;
  margin-bottom: 10px;
}
.home > .bottom > .desc {
  font-size: 16px;
  text-align: center;
  color: #153a04dc;
  font-weight: 500;
  margin-bottom: 20px;
}
.home > .bottom > a {
  text-decoration: none;
  background: #57bc2c;
  font-size: 16px;
  padding: 10px 18px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
}

@media screen and (max-width: 1300px) {
  .home > .hero {
    scale: 0.8;
  }
  .home > .features {
    scale: 0.8;
  }
}

@media screen and (max-width: 1050px) {
  .home > .hero {
    width: 100%;
    padding: 30px;
  }
  .home > .features {
    scale: 1;
    align-items: center;
    width: 10%;
  }
  .home > .features > .list {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
  .home > .hero > .head {
    font-size: 22px;
    width: 100%;
  }
  .home > .hero > .desc {
    font-size: 13px;
    width: 100%;
    text-align: start;
  }
  .home > .bottom {
    width: 300px;
    text-align: center;
  }
}
