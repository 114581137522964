.quizzes {
  min-height: 100vh;
  background: #57bc2c49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quizzes > .container {
  width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
}
.quizzes > .container > .header {
  font-size: 26px;
  color: #1c4d06;
  font-weight: 600;
}
.quizzes > .container > .main {
  display: flex;
  justify-content: space-between;
  width: 1200px;
  margin-top: 12px;
}
.quizzes > .container > .main > .left {
  background: #1c4d0673;
  width: 840px;
  padding: 20px 15px;
  border-radius: 8px;
}
.quizzes > .container > .main > .left > .top {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
}
.quizzes > .container > .main > .left > .top > div {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}
.quizzes > .container > .main > .left > .top > button {
  border: 0;
  padding: 3px 10px;
  font-size: 14px;
  border-radius: 10px;
  font-weight: 600;
  background: #1c4d06;
  color: white;
  letter-spacing: -0.1px;
}
.quizzes > .container > .main > .left > .select {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.quizzes > .container > .main > .left > .select > div {
  display: flex;
  gap: 6px;
  align-items: flex-start;
}
.quizzes > .container > .main > .left > .select > div > input {
  width: 16px;
  height: 16px;
}
.quizzes > .container > .main > .left > .select > div > label {
  color: white;
  font-size: 18px;
}
.quizzes > .container > .main > .right {
  display: flex;
  flex-direction: column;
  width: 340px;
  gap: 20px;
}
.quizzes > .container > .main > .right > div {
  background: #1c4d0673;
  width: 340px;
  padding: 16px 15px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;

  gap: 10px;
}
.quizzes > .container > .main > .right > div > div {
  font-weight: 600;
  color: white;
  font-size: 20px;
}
.quizzes > .container > .main > .right > div > select {
  border: 0;
  outline: 0;
  padding: 10px 10px;
  font-size: 15px;
  font-weight: 500;
  background: #1c4d067c;
  color: white;
  border-radius: 6px;
}

.quizzes > .container > button {
  text-decoration: none;
  background: #57bc2c;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  width: fit-content;
  border: 0;
  margin-top: 20px;
}
.quizQuestions {
  min-height: 100vh;
  background: #57bc2c49;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.quizQuestions > .quizCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
}
.quizQuestions > .title {
  font-size: 32px;
  font-weight: 600;
  margin-top: 20px;
  color: #1c4d06;
  letter-spacing: 2px;
}
.quizQuestions > .quizCont > .quiz {
  display: flex;
  flex-direction: column;
  width: 900px;
  background: #1c4d067c;
  padding: 14px 20px;
  border-radius: 10px;
  color: white;
  gap: 10px;
}

.quizQuestions > .quizCont > .quiz > .question {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.quizQuestions > .quizCont > .quiz > button {
  border: 1px solid #fff;
  font-size: 18px;
  padding: 12px 15px;
  border-radius: 8px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  background: #00000000;
  text-align: start;
}
.quizQuestions > .quizCont > .quiz > .answer {
  font-size: 18px;
}
.quizQuestions > a {
  text-decoration: none;
  background: #57bc2c;
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 8px;
  color: white;
  font-weight: 600;
  width: fit-content;
  border: 0;
  margin-bottom: 20px;
}

.submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background: #57bc2c49;
  justify-content: center;
}
.submit > div {
  font-size: 22px;
  font-weight: 600;
  color: #1c4d06;
  margin-bottom: 7px;
}
.submit > span {
  display: flex;
  flex-direction: row;
  gap: 14px;
  margin-top: 8px;
}
.submit > span > a {
  text-decoration: none;
  background: #57bc2c;
  font-size: 16px;
  padding: 10px 24px;
  border-radius: 6px;
  color: white;
  font-weight: 600;
}
@media screen and (max-width: 1250px) {
  .quizzes > .container {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0;
  }
  .quizzes > .container > .main {
    display: flex;
    justify-content: center;
    width: 95%;
    margin-top: 12px;
    gap: 20px;
  }
  .quizzes > .container > .main > .left {
    background: #1c4d0673;
    width: fit-content;
    padding: 20px 15px;
    border-radius: 8px;
  }
  .quizzes > .container > .main > .left > .top {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 1050px) {
  .quizzes > .container > .header {
    font-size: 22px;
    color: #1c4d06;
    font-weight: 600;
  }
  .quizzes > .container > .main {
    flex-direction: column;
    align-items: center;
  }
  .quizQuestions > .quizCont {
    width: 100%;
    align-items: center;
    padding: 30px 0;
  }
  .quizQuestions > .quizCont > .quiz {
    width: 95%;
  }
}
@media screen and (max-width: 500px) {
  .quizzes > .container > .header {
    font-size: 20px;
    color: #1c4d06;
    font-weight: 600;
  }
  .quizzes > .container > .main > .right {
    display: flex;
    flex-direction: column;
    width: 300px;
    gap: 20px;
  }
  .quizzes > .container > .main > .right > div {
    background: #1c4d0673;
    width: 300px;
    padding: 16px 15px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    gap: 10px;
  }

  .quizzes > .container > .main > .left > .top {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;
    margin-bottom: 20px;
  }
  .quizzes > .container > .main > .left > .top > div {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
  }
  .quizzes > .container > .main > .left > .top > button {
    border: 0;
    padding: 3px 8px;
    font-size: 10px;
    border-radius: 10px;
    font-weight: 600;
    background: #1c4d06;
    color: white;
    letter-spacing: -0.1px;
  }
  .quizzes > .container > .main > .left > .select > div > label {
    color: white;
    font-size: 15px;
  }

  .quizQuestions > .quizCont > .quiz {
    padding: 12px 12px;
    border-radius: 6px;
  }

  .quizQuestions > .quizCont > .quiz > button {
    border: 1px solid #fff;
    font-size: 14px;
    padding: 8px 7.5px;
    border-radius: 8px;
    color: white;
    font-weight: 500;
    cursor: pointer;
    background: #00000000;
    text-align: start;
  }
  .quizQuestions > .quizCont > .quiz > .question {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  .quizQuestions > .quizCont > .quiz > .answer {
    font-size: 14px;
  }
  .quizQuestions > a {
    text-decoration: none;
    background: #57bc2c;
    font-size: 18px;
    padding: 10px 20px;
    border-radius: 8px;
    color: white;
    font-weight: 600;
    width: fit-content;
    border: 0;
    margin-bottom: 20px;
  }
  .quizQuestions > .title {
    font-size: 26px;
    font-weight: 600;
    margin-top: 20px;
    color: #1c4d06;
    letter-spacing: 2px;
  }
}
